/*******************************************************************************
 * Variables used throughout the theme.
 * To adjust anything, simply edit the variables below and rebuild the theme.
 ******************************************************************************/
@use 'sass:color';

// Colors
$red-color:           #FF3636 !default;
$red-color-dark:      #B71C1C !default;
$orange-color:        #F29105 !default;
$blue-color:          #0076df !default;
$blue-color-dark:     #00369f !default;
$cyan-color:          #2698BA !default;
$light-cyan-color:    color.adjust($cyan-color, $lightness: 25%);
$green-color:         #00ab37 !default;
$green-color-lime:    #B7D12A !default;
$green-color-dark:    #009f06 !default;
$green-color-light:   #ddffdd !default;
$green-color-bright:  #11D68B !default;
$purple-color:        #800080 !default;
$light-purple-color:  color.adjust($purple-color, $lightness: 25%);
$pink-color:          #f92080 !default;
$pink-color-light:    #ffdddd !default;
$yellow-color:        #efcc00 !default;

$grey-color:          #828282 !default;
$grey-color-light:    color.adjust($grey-color, $lightness: 40%);
$grey-color-dark:     color.adjust($grey-color, $lightness: -25%);

$white-color: #ffffff !default;
$black-color: #000000 !default;


// Theme colors

$code-bg-color-light:     rgba($purple-color, 0.05);
$code-bg-color-dark:      #2c3237 !default;

$max-content-width: 100% !default;
