img + em, table + p > em {
  display: inherit;
  text-align: center;
}

.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
  border-color: var(--global-theme-color);
}

.lg-counter span{
  color: #999;
}
